import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import { PreHeader } from '../theme/typography';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import Spacer from '../components/spacer';
import CTABlock from '../components/ctaBlock';
import AnimatedStripe from '../components/animatedStripe';
import BottomCut from '../components/bottomCut';

// Asset Imports
import Stripes05 from '../images/stripes/stripes05.inline.svg';

//Function Imports
import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const ProductCards = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 32px;
  gap: 32px;
`;

const CoverContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  overflow: hidden;
`;

const ProductCardImgOverlay = styled.div`
  ${CoverContainer}
  background: var(--blue1);
  opacity: 0.5;
  z-index: -2;
`;

const ProductCardImg = styled(GatsbyImage)`
  ${CoverContainer}
  z-index: -3;

  object-fit: cover;

  > * {
    object-position: center top;
  }
`;

const ProductTitle = styled(PreHeader)`
  color: var(--white);
  transition: color var(--transitionDuration) ease-in-out;
`;

const ProductCard = styled(Link)`
  --transitionDuration: 0.5s;

  width: 350px;
  height: 160px;
  border-radius: 8px;

  display: grid;
  place-items: center;
  position: relative;
  z-index: 0;
  box-shadow: var(--box-shadow-xl);

  ::after {
    content: '';
    z-index: -4;
    position: absolute;
    inset: 0;
    border-radius: 8px;
    box-shadow: var(--box-shadow-xxl);
    outline: 2px solid var(--accent);
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @media (pointer: fine) {
    &:hover {
      ${ProductCardImgOverlay}, ${ProductCardImg} {
        transform: scale(1.025);
      }
      ::after {
        transform: scale(1.025);
        opacity: 1;
      }
    }
  }
`;

const ProductsStripes = styled(AnimatedStripe)`
  position: absolute;
  right: -10%;
  bottom: 0;
  width: 80%;
  z-index: 1;

  svg > path:first-child {
    fill: var(--blue5);
  }

  @media (min-width: 1200px) {
    right: 0;
    bottom: 10%;
    width: 50%;
  }
`;

const ProductsContainer = styled(Container)`
  z-index: 2;
  position: relative;
`;

const ProductsPage = ({ data }) => {
  return (
    <>
      <SEO title='Products' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section light cuts={'bottomCut'} style={{ overflowX: 'clip' }}>
        <ProductsStripes direction='right'>
          <Stripes05 />
        </ProductsStripes>
        <ProductsContainer>
          <CenteredHeaderBlock data={data.sanityProductsPage.headerBlock} />
          <Spacer size={80} />
          <ProductCards>
            {shuffleArray(data.allSanityProductPage.nodes).map(
              ({ productTag, featuredVideo }) => {
                return (
                  <ProductCard to={`/products/${productTag.slug.current}`}>
                    <ProductTitle>{productTag.label}</ProductTitle>
                    <ProductCardImgOverlay />
                    <ProductCardImg
                      image={featuredVideo.thumbnail.asset.gatsbyImageData}
                      alt={productTag.label}
                    />
                  </ProductCard>
                );
              },
            )}
          </ProductCards>
        </ProductsContainer>
        <BottomCut dark />
      </Section>
      <Section dark cuts={'topCut'}>
        <Container>
          <CTABlock data={data.sanityProductsPage.ctaBlock} />
        </Container>
        {/* <BottomCut dark /> */}
      </Section>
    </>
  );
};

// GraphQL Query
export const productPageQuery = graphql`
  {
    sanityProductsPage {
      headerBlock {
        ...SanityCenteredHeaderBlockFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
    allSanityProductPage {
      nodes {
        productTag {
          label
          icon {
            name
          }
          slug {
            current
          }
        }
        featuredVideo {
          thumbnail {
            ...SanityImageFragment
          }
        }
      }
    }
  }
`;

export default ProductsPage;
